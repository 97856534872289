import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, FirstP, Emoji } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>

        <FirstP>Continuing the journey to implement Bluetooth audio on my old BMW, an updated version of the PCB has arrived. In this post we will take a look at the design of the circuit board, and test if the overheating and sound quality issues are fixed with this latest iteration of the design.</FirstP>
         
        <p>Before I go into the details, let me first say that I am really happy with how the circuit board turned out. I am happy that I managed to reduce the size even more.
            But just in general I think this is just a beautiful board. That being said, I might have some bias <Emoji e="😉" />.
        </p>

        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Final result" />
        <Caption>The third iteration of the PCB is a beauty</Caption>

        <p>The assembly process I use for my surface mount projects is quite simple, and consists of four steps:</p>

        <ol>
            <li>I order the PCB's together with a stencil and use that to apply solder paste to the board</li>
            <li>With tweezers and my too big and too shaky hands I place the components on the board manually</li>
            <li>I use nothing more than a skillet on a stove to melt the solder paste and solder the boards. This works quite well for me so far.</li>
            <li>Inspection and touch up with a soldering iron if needed. Since I keep getting better at the first three steps this is needed less and less.</li>            
        </ol>

        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="3D printed tooling" />
        <Caption>This was the first time I used a small 3D printed part to help align the stencil to the pads</Caption>

        <p>
            As a recap, there were two issues with the previous board. The linear regulator would overheat at 14V, the audio connections were not routed 
            correctly to the connector. To start with the second issue, in my previous log I tested and showed how this should be adjusted, and these changes indeed
            resolve the issues I had. Now it is pin for pin plug and play with my PH7850 radio. </p>
            
        <p>For the overheating
            issue I replaced the linear regulator with a buck converter, and this also proved to be succesful. 
            I used a LM2594 from TI, which is not too difficult to use. Following the data sheet I selected an inductor, in- and output capacitors and a diode and integrated this 
            on my PCB. It is nice to see that the current draw of the board is also reduced 
            by more than 50% because of this change. I already tested the board for several hours straight at 14V and it never shut down or stopped working.       
        </p>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Size comparison" />
        <Caption>Partly due to the removal of the DC/DC converter The size of the PCB was reduced even further.</Caption>

        <p>
            Finally, I thought that the isolated DC/DC converter which shielded the analog audio circuit might have been overkill, so I removed it in this new board. I tried to use good practices in designing the PCB
            to keep the noise to a minimum, and I think I am happy with this choice. I will admit that the noise might have increased ever so slightly, but the signal to noise ratio
            is still more than good enough for my standards, and in normal situations the noise is not really noticeable.
        </p>
        
        <h3>Next steps</h3>

        <p>
            Now that we have a final hardware version, there are a few minor tasks remaining. 
            First of all, I still need to make some software improvements on the Bluetooth side to make sure that paired devices will be reconnected automatically.
            Next to that I will also design and 3D print a housing for final assembly and integration in my car.
        </p>
       
    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "third.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "third2.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    
    img3: file(relativePath: { eq: "third3.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
